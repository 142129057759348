import React, { useEffect, useState, createRef } from 'react';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';

// global services
import { downloadImage } from 'js/library/services/StorageManager.js';

import {
  Box,
  Button,
  Typography,
  Container,
  Modal,
  Snackbar,
  CircularProgress,
} from '@mui/material';

// icons
import { SentimentDissatisfied } from '@mui/icons-material';

// components
import Header from '../Header';
import { getUserInfo } from 'js/library/utils/helpers';

import { makeStyles, styled } from '@mui/styles';
import { AttendanceServices } from '../AttendanceScreen/AttendanceServices';

// images
import placeholderItem from 'styles/assets/placeholder/placeholder_thumbnail.png';
import { PromotionModalForm, Fade } from './Modals';
import { useModal, useOptions, usePromotions, useVouchers } from '../Context/Attendance';
import { useHistory } from 'react-router-dom';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1';
import { connect } from 'react-redux';
import { voucherSendAction } from 'js/core/actions/voucherSendAction';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '100%',
  },

  label: {
    fontWeight: 'bold',
  },

  content: {
    width: '80%',
  },

  disableText: {
    opacity: 0.5,
  },

  pointsInput: {
    fontSize: 15,

    padding: '3px 7px 2px',

    border: '1px solid rgba(0, 0, 0, .5)',
    borderRadius: 10,

    outline: 'none',

    '&:focus': {
      border: '2px solid',
      borderColor: theme.palette.primary.dark,
    },
  },

  optionButton: {
    marginBottom: theme.spacing(1),

    fontWeight: 'bold',
    textTransform: 'capitalize',

    '&+button': {
      backgroundColor: '#6E3296',
      marginLeft: theme.spacing(1),

      '&:hover': {
        backgroundColor: '#631f91',
      },
    },
  },

  walletContainer: {
    height: 120,

    boxShadow: theme.shadows[5],

    borderRadius: 15,

    '&+div': {
      marginLeft: theme.spacing(5),
    },

    '&>img': {
      borderRadius: '15px 0 0 15px',
    },
  },

  walletTextContainer: {
    width: 186,

    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: 0,

    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },

  walletDescriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },

  walletContent: {
    width: '70%',
    margin: '0 auto',
    padding: '1.4rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  walletThumb: {
    width: 120,
    height: 120,
    [theme.breakpoints.down('xs')]: {
      width: 135,
      objectFit: 'cover',
    },
  },

  sadIcon: {
    height: 100,
    width: 100,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '750px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '630px',
      maxWidth: '340px',
      overflow: 'auto',
    },
  },
}));

const Line = styled(Box)({
  height: 1,

  backgroundColor: 'lightgray',
});

const Wallet = ({ classes, coupon }) => {
  const { setSelectedVoucher, selectedVoucher } = useVouchers();

  const { key, title, type, state, vouchers, limit, clientId } = coupon;
  const [thumbnail, setThumbnail] = useState(placeholderItem);

  const ref = createRef();

  // const isSelectedWallet = key === coupon.key;

  const downloadCouponImage = () =>
    coupon.thumbnail &&
    downloadImage('voucher', coupon.thumbnail)
      .then((downloaded) => {
        setThumbnail(downloaded);
      })
      .catch((error) => {
        return error;
      });

  const vouchersLength = vouchers ? Object.keys(vouchers).length : 0;

  const handleSelectWallet = () =>
    setSelectedVoucher({
      key,
      title,
      type,
      vouchersLength: vouchersLength,
      vouchers,
      limit,
      clientId,
    });

  useEffect(() => {
    // isSelectedWallet &&
    //   ref.current.scrollIntoView({
    //     behavior: "smooth",
    //     inline: "center",
    //   });

    downloadCouponImage();
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.walletContainer}
      key={key}
      maxWidth={365}
      maxHeight={139}
      ref={ref}
      onClick={handleSelectWallet}
      style={{
        border: selectedVoucher.key === coupon.key ? '5px solid #08BAD0' : 'none',
      }}
    >
      <img src={thumbnail} alt="Thumbnail Wallet" className={classes.walletThumb} />
      <div className={classes.walletTextContainer}>
        <Typography variant="body1" component="span">
          {coupon.title || 'Nome da promoção'}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="span"
          classes={{ body2: classes.walletDescriptionText }}
        >
          {coupon.description || 'Descrição da promoção'}
        </Typography>
        {state === 'ongoing' && type === 'promo' && vouchersLength > 0 && (
          <Typography color="textSecondary" component="span">
            {Object.keys(vouchers).length}/{limit}
          </Typography>
        )}
      </div>
    </Box>
  );
};

const ClientScreenComponent = ({ location, filteredWallets, voucherSendComponent, ...props }) => {
  const { userv3, ...walletInfo } = location.state;
  const [informationLoading, setInformationLoading] = useState(false);
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [userData, setUserData] = useState(userv3);

  const [walletData, setWalletData] = useState(
    walletInfo.activeCoupons?.filter(
      ({ establishmentId, ...item }) =>
        (item.status === 'enviado' || item.state === 'ongoing') &&
        establishmentId === props.match.params.id
    )
  );

  const history = useHistory();

  const { setOptionSelected } = useOptions();
  const { openModal, setOpenModal } = useModal();
  const { setSelectedPromotion } = usePromotions();
  const { selectedVoucher } = useVouchers();

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const attendanceServices = new AttendanceServices({});

  const classes = useStyles();

  const handleVouchersModal = () => {
    setOptionSelected('promotions');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedPromotion({});
    setOpenModal(false);
  };

  const handleVoucherConsume = () => {
    setInformationLoading(true);
    setLoadingWallet(true);

    const type = 'validation';

    if (selectedVoucher.type === 'promo') {
      const templateId = Object.values(selectedVoucher.vouchers)[0].templateId.split('_')[0];

      const batchId = attendanceServices.getVouchers(templateId).then((result) => result?.batchId);

      const clientList = new File([selectedVoucher.clientId], 'fakeFile.txt');

      const voucherConsumed = true;

      voucherSendComponent(templateId, batchId, clientList, voucherConsumed, () => {
        setInformationLoading(false);
        updateWallet();
        toast.success('Promoção consumida com sucesso.', { autoClose: 2000 });
      });
    } else {
      attendanceServices
        .validateVoucher(
          userData.uId,
          getUserInfo().triiboId,
          selectedVoucher.key,
          location.state.lat,
          location.state.long,
          type,
          userData.triiboId
        )
        .then((result) => {
          setInformationLoading(false);
          toast.success('Cupom consumido com sucesso!', { autoClose: 2000 });
          updateWallet();
        })
        .catch((err) => {
          setInformationLoading(false);
          toast.error('Erro ao consumir cupom!', { autoClose: 2000 });
          setLoadingWallet(false);
        });
    }
  };

  const updateWallet = () => {
    setTimeout(() => {
      getWallet_v1(userData.uId, userData.triiboId).then((walletReceived) => {
        if (walletReceived != null) {
          let activeCoupons = [];
          let inactiveCoupons = [];

          if (walletReceived.coupons !== null) {
            Object.keys(walletReceived.coupons).map(function (item, i) {
              let element = Object.keys(walletReceived.coupons)[i];
              walletReceived.coupons[item].key = element;
              if (
                walletReceived.coupons[item].state === 'ongoing' ||
                walletReceived.coupons[item].status === 'enviado'
              ) {
                return activeCoupons.push(walletReceived.coupons[item]);
              } else {
                return inactiveCoupons.push(walletReceived.coupons[item]);
              }
            });
          }

          activeCoupons = activeCoupons.filter(
            ({ establishmentId, ...item }) =>
              (item.status === 'enviado' || item.state === 'ongoing') &&
              establishmentId === props.match.params.id
          );

          const data = {
            userv3: userData,
            activeCoupons,
            inactiveCoupons,
            totalPoints: walletReceived.totalPoints,
          };

          setWalletData(activeCoupons);
          setLoadingWallet(false);
          // history.replace({
          //   state: { ...location.state, activeCoupons, inactiveCoupons },
          // });
          history.replace(`/estabelecimento/${props.match.params.id}/atendimento/cliente`, {
            ...data,
          });
        }
      });
    }, 1500);
  };

  const handleSearch = (userInfo, wallet) => {
    setUserData(userInfo.userv3);
    setWalletData(
      wallet.activeCoupons?.filter(
        ({ establishmentId, ...item }) =>
          (item.status === 'enviado' || item.state === 'ongoing') &&
          establishmentId === props.match.params.id
      )
    );
    history.replace(`/estabelecimento/${props.match.params.id}/atendimento/cliente`, {
      ...userInfo,
      ...wallet,
    });
  };

  // // CAROUSEL OPTIONS
  const options = {
    items: 2,
    nav: true,
    dots: false,
    rewind: false,
    center: true,
  };

  const renderWallets = () =>
    walletData.length > 0 ? (
      walletData.map((coupon) => <Wallet {...{ classes, coupon }} />)
    ) : (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <SentimentDissatisfied color="disabled" classes={{ colorDisabled: classes.sadIcon }} />
        <br />
        <Typography variant="body2" color="textSecondary" component="span">
          Este usuário não possui cupons do seu estabelecimento.
        </Typography>
      </Box>
    );

  const isWalletSelected = Object.values(selectedVoucher).length > 0 ? true : false;

  return (
    <Container maxWidth={false} classes={{ root: classes.root }}>
      <Header title="Cliente" action={handleSearch} />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        disableAutoFocus
        disableEnforceFocus
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <PromotionModalForm
              {...{ classes }}
              userData={userData}
              walletData={walletData}
              walletInfo={walletInfo}
              filteredWallets={walletData}
            />
          </div>
        </Fade>
      </Modal>

      <br />

      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="body2"
          color="primary"
          component="span"
          classes={{ body2: classes.label }}
        >
          {userData?.name || 'Nome do usuário'}
        </Typography>

        <Typography
          variant="body2"
          color="primary"
          component="span"
          classes={{ body2: classes.label }}
        >
          Pontos: {walletInfo.totalPoints || '0'}
        </Typography>
      </Box>

      <br />
      <hr />
      <br />

      <Container display="flex" flexDirection="column" alignItems="center">
        {walletData.length > 0 && (
          <Typography
            variant="body2"
            color="primary"
            component="span"
            classes={{ body2: classes.label }}
          >
            Carteira
          </Typography>
        )}
        <Box className={classes.walletContent}>
          <OwlCarousel options={options}>
            {loadingWallet ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={40} color="primary" />
              </Box>
            ) : (
              renderWallets()
            )}
          </OwlCarousel>
        </Box>
        <>
          <Container maxWidth="md">
            <br />

            <br />
          </Container>

          <Line />
          <br />

          <Box display="flex" flexDirection="column" alignItems="center">
            <div>
              <Button
                variant="contained"
                color="primary"
                classes={{ contained: classes.optionButton }}
                disabled={selectedVoucher.type === 'promo'}
                onClick={handleVouchersModal}
              >
                Dar cupom
              </Button>

              <Button
                variant="contained"
                color="primary"
                classes={{ contained: classes.optionButton }}
                onClick={handleVoucherConsume}
                disabled={!isWalletSelected}
              >
                {informationLoading ? (
                  <CircularProgress
                    size={25}
                    color="default"
                    classes={{ root: classes.loadingIcon }}
                  />
                ) : selectedVoucher.type === 'promo' ? (
                  'enviar Cupom'
                ) : (
                  'Consumir Cupom'
                )}
              </Button>
            </div>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={snackBarMessage.length > 0}
            onClose={() => setSnackBarMessage('')}
            autoHideDuration={4000}
            message={snackBarMessage}
          />
        </>
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.voucherSendComponent.loading,
    success: state.voucherSendComponent.success,
    error: state.voucherSendComponent.error,
  };
};
//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  voucherSendComponent: (templateId, batchId, emailFile, consumed, callback) =>
    voucherSendAction(dispatch, templateId, batchId, emailFile, consumed, callback),
});
const ClientScreen = connect(mapStateToProps, mapDispatchToProps)(ClientScreenComponent);

export default ClientScreen;
