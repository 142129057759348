import axios from 'axios';
import authToken from '../authToken';
import cfac22 from '../../cfac22';

export const postPartnerCode = async (partnerCodesData) => {
  try {
    const token = await authToken();

    const response = await axios.post(
      `${cfac22('API_HOST_V2')}/campaign/partnerCode/createPartnerCode`,
      partnerCodesData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
