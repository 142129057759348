import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export function inputCpfInBatch({ fileName, campaignId }) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await authToken();

      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/campaignUser/inputCpfInBatch`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          fileName,
          campaignId,
        },
      };

      const response = await axios(config);
      resolve(response.data);
    } catch (error) {
      reject(error.response ? error.response.data : error.message);
    }
  });
}
