import React, { useState, useEffect } from 'react';
import { GoBackHeader } from '../../RelationshipClub/GoBackHeader';

//Styles
import {
  CircularProgress,
  Container,
  Pagination,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import ReactSwitch from 'react-switch';
import { FaTrash } from 'react-icons/fa';
import {
  DeleteButtonContainer,
  DeleteUserButton,
  LoadingContainer,
  TableHeaderMui5,
  TbContainer,
  TbContent,
  ContainerSearch,
} from './EditWhitelistStyle';

//Components
import { HeaderTitle } from '../../RelationshipClub/HeaderTitle';
import usePagination from 'js/components/Establishment/RelationshipScreen/usePagination';
import RenderInputDynamic from 'js/components/Configurations/SearchUser/RenderInputDynamic';

//Libs
import { toast } from 'react-toastify';
//Apis
import {
  deleteUserPartnerWhitelist,
  getUsersPartnerWhitelist,
  inactiveUserPartnerWhitelist,
} from 'js/library/utils/API/usersPartnerWhitelist';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';

export function EditWhitelist({}) {
  const partnerId = localStorage.getItem('partnerIdClube');
  const [whitelist, setWhitelist] = useState([]);
  const [loadingWhiteList, setLoadingWhiteList] = useState(false);
  const [page, setPage] = useState(1);
  const [accessField, setAccessField] = useState({});
  const PER_PAGE = 20;
  const [inputSearch, setInputSearch] = useState('');

  const handleGetUser = async (event) => {
    event.preventDefault();
    setLoadingWhiteList(true);

    try {
      const response = await getUsersPartnerWhitelist(partnerId);

      const filteredResponse = response.response.filter((user) =>
        user.token.toLowerCase().includes(inputSearch.toLowerCase())
      );

      if (filteredResponse.length > 0) {
        setWhitelist(filteredResponse);
      } else {
        setWhitelist([{ token: 'Chave não encontrada', isError: true }]);
      }
    } catch (error) {
      console.error('Erro ao buscar chave de acesso:', error);
      setWhitelist([{ token: 'Erro ao buscar chave', isError: true }]);
    } finally {
      setLoadingWhiteList(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const partner = await GetPartnerUserForms(partnerId);
        const fieldsWithIndexKeyTrue = partner.formFields.filter(
          (field) => field.indexKey === true
        );
        if (fieldsWithIndexKeyTrue.length > 0) {
          setAccessField(fieldsWithIndexKeyTrue[0]);
        }
      } catch (error) {
        console.error('Ocorreu um erro:', error);
      }
    };
    fetchData();
  }, [partnerId]);

  useEffect(() => {
    setLoadingWhiteList(true);
    getUsersPartnerWhitelist(partnerId)
      .then((users) => {
        setWhitelist(users.response);
      })
      .catch((e) => {
        console.error('Erro ao carregar whitelist:', e);
      })
      .finally(() => {
        setLoadingWhiteList(false);
      });
  }, [partnerId]);

  const count = Math.ceil(whitelist.length / PER_PAGE);
  const _DATA = usePagination(whitelist, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChangeStatusUser = async (userPartnerId, status) => {
    setLoadingWhiteList(true);
    const updateInfo = { isEnabled: status };
    const resultInactiveUser = await inactiveUserPartnerWhitelist(
      partnerId,
      userPartnerId,
      updateInfo
    );
    if (resultInactiveUser.data.response) {
      const rerrender = await getUsersPartnerWhitelist(partnerId);
      setWhitelist(rerrender.response);
    }
    setLoadingWhiteList(false);
  };

  const deleteUserWhitelist = async (userPartnerId) => {
    setLoadingWhiteList(true);
    const resultDeleteUser = await deleteUserPartnerWhitelist(partnerId, userPartnerId);
    if (resultDeleteUser.data.response) {
      const rerrender = await getUsersPartnerWhitelist(partnerId);
      setWhitelist(rerrender.response);
      toast.success('Usuário deletado com sucesso.');
    }
    setLoadingWhiteList(false);
  };

  return (
    <Container
      style={{
        padding: '4rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      maxWidth={false}
    >
      <GoBackHeader />
      <HeaderTitle pageInfo="Gerenciar Whitelist" />
      <ContainerSearch>
        <RenderInputDynamic
          fieldInfos={accessField}
          inputValue={inputSearch}
          setInputValue={setInputSearch}
          handleSubmit={handleGetUser}
        />
      </ContainerSearch>

      {whitelist.length !== 0 ? (
        <TbContainer>
          <TbContent size="medium">
            <TableHeaderMui5>
              <TableRow>
                <TableCell align="left">
                  <strong>Chave de acesso</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Status</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Excluir</strong>
                </TableCell>
              </TableRow>
            </TableHeaderMui5>
            <TableBody>
              {_DATA.currentData().map((user) =>
                user.token === '' ? null : (
                  <TableRow key={user.token}>
                    <TableCell align="left" style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                      {user.token}
                    </TableCell>
                    <TableCell align="left">
                      {loadingWhiteList ? (
                        <CircularProgress style={{ color: '#6e3296' }} size="2rem" />
                      ) : (
                        <ReactSwitch
                          onColor="#6e3296"
                          checked={user.isEnabled}
                          onChange={() => handleChangeStatusUser(user.token, !user.isEnabled)}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {loadingWhiteList ? (
                        <CircularProgress style={{ color: '#FF0000' }} size="2rem" />
                      ) : (
                        <DeleteButtonContainer>
                          <DeleteUserButton onClick={() => deleteUserWhitelist(user.token)}>
                            <FaTrash style={{ color: '#fff', fontSize: '0.9rem' }} />
                          </DeleteUserButton>
                        </DeleteButtonContainer>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </TbContent>
        </TbContainer>
      ) : (
        loadingWhiteList && (
          <LoadingContainer>
            <CircularProgress size="100px" />
          </LoadingContainer>
        )
      )}
      <Pagination
        sx={{ mt: 3 }}
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChangePage}
      />
    </Container>
  );
}
