import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Paper,
  Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import { inputCpfInBatch } from 'js/library/utils/API/Campaigns/apiInputCpfInBatch';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { blobToBase64, separateBase64String } from 'js/library/utils/helpers';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';
import { Paperclip, FileText, MagnifyingGlass } from 'phosphor-react';
import { getAllUsersByCampaignId } from 'js/library/utils/API/Campaigns/apiGetAllUsersByCampaignId';
import Pagination from '@mui/material/Pagination';

export default function ListUsersCampaign() {
  const { id: batchId } = useParams();
  const [dataUser, setDataUser] = useState({ fileThumbnail: null });
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const fileInputRef = useRef(null);
  const rowsPerPage = 21;
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (batchId) fetchUsers(page);
  }, [batchId, page]);

  const fetchUsers = async (currentPage) => {
    try {
      setLoading(true);
      const from = (currentPage - 1) * rowsPerPage;
      const response = await getAllUsersByCampaignId(batchId, from, rowsPerPage);

      if (response && response.result) {
        const fetchedUsers = response.result.map((user) => {
          const userKey = Object.keys(user).find((key) => key !== 'id' && key !== 'campaignId');
          return {
            id: user.id,
            userKey: userKey,
            status: user[userKey] || 'Status não disponível',
          };
        });

        setUsers(fetchedUsers);
        setHasMore(fetchedUsers.length === rowsPerPage);
      }
    } catch (error) {
      toast.error('Erro ao carregar usuários.');
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!dataUser.fileThumbnail) {
      newErrors.fileThumbnail = 'Selecionar um arquivo é obrigatório';
    }
    return newErrors;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return toast.error('Nenhum arquivo selecionado.');

    const validFileTypes = ['text/plain', 'text/csv'];
    if (!validFileTypes.includes(file.type)) {
      return toast.error('Tipo de arquivo inválido. Selecione um arquivo .txt ou .csv.');
    }

    setDataUser({ fileThumbnail: file });
    setFileName(file.name);
    setErrors({});
  };

  // useEffect(() => {
  //   handleSearch();
  // }, [searchTerm, users]);

  // const handleSearch = () => {
  //   const searchLower = searchTerm.toLowerCase();

  //   const filtered = users.filter((user) => {
  //     const userId = user.id ? user.id.toString().toLowerCase() : '';
  //     return userId.includes(searchLower);
  //   });

  //   setFilteredUsers(filtered);
  //   setPage(1);
  // };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Por favor, selecione um arquivo válido.');
      return;
    }

    try {
      setLoading(true);
      const base64 = await blobToBase64(dataUser.fileThumbnail);
      const newFile = separateBase64String(base64);

      const uploadedFileName = await uploadFiles('campaignUserCpf', {
        mimeType: newFile.mimeType,
        fileName: `${batchId}.${newFile.mimeType === 'text/csv' ? 'csv' : 'txt'}`,
        buffer: newFile.buffer,
      });

      const realFileName = uploadedFileName.split('/').pop().split('?')[0];
      await inputCpfInBatch({
        fileName: `campaignUserCpf/${realFileName}`,
        campaignId: batchId,
      });

      toast.success('Arquivo enviado com sucesso!');
      fetchUsers(1);
      setIsModalOpen(false);

      setDataUser({ fileThumbnail: null });
      setFileName('');
    } catch (error) {
      toast.error(error.message || 'Erro ao enviar o arquivo.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <S.ContainerUserCampaign>
      <S.Container>
        <GoBackHeader />
        <D.TriiboH1 style={{ textAlign: 'center' }}>Membros da Campanha</D.TriiboH1>
        <S.SearchSection>
          {/* <S.InputBox>
            <S.Input
              placeholder="Procurar membro da campanha por chave"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <S.SearchIconButton onClick={handleSearch}>
              <MagnifyingGlass size={25} weight="bold" />
            </S.SearchIconButton>
          </S.InputBox> */}

          <S.AddUserButton onClick={() => setIsModalOpen(true)}>
            <span>+</span>
            <D.TriiboH4>Adicionar Membro</D.TriiboH4>
          </S.AddUserButton>
        </S.SearchSection>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="medium" aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <strong>Chave do Membro</strong>
                  </TableCell>
                  <TableCell align="left">
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell align="left">{user.userKey}</TableCell>
                      <TableCell align="left">{user.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="body2">Nenhum usuário encontrado.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          sx={{ mt: 3 }}
          count={hasMore ? page + 1 : page}
          page={page}
          size="large"
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
        {/* Modal */}
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box
            sx={{
              outline: 'none',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              borderRadius: '15px',
              bgcolor: 'background.paper',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <D.TriiboH3 style={{ textAlign: 'center', marginBottom: '2rem' }}>
              Adicionar novo membro
            </D.TriiboH3>
            <Typography
              variant="body2"
              sx={{ padding: '0', textAlign: 'center', marginBottom: '1rem' }}
            >
              Selecione um arquivo no formato <strong>.txt</strong> ou <strong>.csv</strong>{' '}
              contendo a chave e o status do(s) membro(s) da campanha.
            </Typography>

            <Paper
              sx={{
                margin: '1rem',
                padding: '1rem',
                width: '100%',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: '0.875rem', marginBottom: '1rem', padding: '0' }}
              >
                <strong>Exemplo de conteúdo: </strong>
              </Typography>
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '0.875rem' }}>
                {` 04803622892; eligible`}
              </pre>
            </Paper>

            <Button
              variant="contained"
              startIcon={<Paperclip size={20} />}
              onClick={() => fileInputRef.current.click()}
              sx={{ width: '100%', marginTop: '0.4rem' }}
            >
              Anexar arquivo
            </Button>
            {fileName && (
              <Typography
                variant="body2"
                sx={{ padding: '0', textAlign: 'center', marginTop: '1rem' }}
              >
                <FileText size={13} /> {fileName}
              </Typography>
            )}
            <input
              type="file"
              accept=".txt, .csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ backgroundColor: '#6E3296', width: '100%', marginTop: '1rem' }}
            >
              Enviar
            </Button>
            {loading && (
              <CircularProgress
                thickness={6}
                size={25}
                sx={{
                  marginTop: '1rem',
                }}
              />
            )}
          </Box>
        </Modal>
      </S.Container>
    </S.ContainerUserCampaign>
  );
}
