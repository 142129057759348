import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// redux imports config
import { connect } from 'react-redux';
import { voucherSendAction } from 'js/core/actions/voucherSendAction';

// helpers
import { maskCEP, randomizeNumbersString } from 'js/library/utils/helpers';
import axios from 'axios';
import { geocoding } from 'js/library/utils/API/apiGeocoding';
import { RegisterUsers } from '../../../library/utils/API/apiRegisterUsersBatch';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

// context
import { useOptions, usePromotions, useModal } from '../Context/Attendance';

// modal animations config
import { useSpring, animated } from 'react-spring/web.cjs';

import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Button,
  CircularProgress,
  Snackbar,
  Grid,
  OutlinedInput,
} from '@mui/material';

import { SentimentDissatisfied } from '@mui/icons-material';
import placeholderItem from 'styles/assets/placeholder/placeholder_thumbnail.png';

// components
import Header from '../Header';

// styles
import { useModalsStyles } from './styles';
import { AttendanceServices } from './AttendanceServices';
import * as S from './styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.0rem',
    marginTop: '20px',
    marginBottom: '20px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 10px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.9)',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '40%',
    minWidth: '300px',
  },
  contentButton: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 0px',
    marginTop: '20px',
    justifyContent: 'end',
  },
  button: {
    marginRight: '20px',
  },
}));

// wallet
export const ConsumeVoucherCard = ({ classes, promotion }) => {
  const { selectedPromotion, setSelectedPromotion } = usePromotions();

  const ref = createRef();

  const { id, templateId, type } = promotion;

  const isSelectedPromotion = id === selectedPromotion.id;

  const handleSelectPromotion = () => setSelectedPromotion({ id, templateId, type });

  useEffect(() => {
    isSelectedPromotion &&
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
        inline: 'center',
      });
  }, [isSelectedPromotion, ref]);

  return (
    <Box width={225} minWidth={225} mr={5}>
      <Card
        className={classes.card}
        ref={ref}
        style={{ border: isSelectedPromotion ? '5px solid #08BAD0' : 'none' }}
      >
        <CardActionArea onClick={handleSelectPromotion}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height={169}
            image={promotion.downloadedThumb}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="body2"
              component="h3"
              align="center"
              classes={{ body2: classes.cardTitle }}
            >
              {promotion.title || 'Nome da Promoção'}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              color="textSecondary"
              component="h6"
              align="center"
              classes={{ body2: classes.cardText }}
            >
              {promotion.description || 'Descrição da promoção...'}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

const PromotionModalCard = ({ classes, promotion }) => {
  const { selectedPromotion, setSelectedPromotion } = usePromotions();

  const ref = createRef();

  const { id, templateId } = promotion;

  const isSelectedPromotion = id === selectedPromotion.id;

  const handleSelectPromotion = () => setSelectedPromotion({ id, templateId });

  useEffect(() => {
    isSelectedPromotion &&
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
        inline: 'center',
      });
  }, [isSelectedPromotion, ref]);

  return (
    <Box width={225} minWidth={225} mr={5}>
      <Card
        className={classes.card}
        ref={ref}
        style={{ border: isSelectedPromotion ? '5px solid #08BAD0' : 'none' }}
      >
        <CardActionArea onClick={handleSelectPromotion}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height={169}
            image={promotion.downloadedThumb || placeholderItem}
            title="Contemplative Reptile"
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="body2"
              component="h3"
              align="center"
              classes={{ body2: classes.cardTitle }}
            >
              {promotion.title || 'Nome da Promoção'}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              color="textSecondary"
              component="h6"
              align="center"
              classes={{ body2: classes.cardText }}
            >
              {promotion.description || 'Descrição da promoção...'}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

const PromotionModalFormComponent = React.memo(({ userInfo, walletInfo, voucherSendComponent }) => {
  const [userData, setUserData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [batchId, setBatchId] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [informationLoading, setInformationLoading] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newBirthDate, setNewBirthDate] = useState('');
  const [newZipCode, setNewZipCode] = useState('');
  const [newCountry] = useState('Brasil');
  const [newState, setNewState] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newNeighborhood, setNewNeighborhood] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newNumber, setNewNumber] = useState('');
  const [newExtra, setNewExtra] = useState('');
  const [newLatitude, setNewLatitude] = useState(0);
  const [newLongitude, setNewLongitude] = useState(0);

  const classInputRegister = useStyles();
  const { location } = useHistory();
  const establishmentId = location.state.establishmentId;
  const establishmentName = location.state.nome;

  const { optionSelected, nameButtonLabel } = useOptions();
  const { isUserRegister, setOpenModal, setIsUserRegister } = useModal();
  const { currentPromotions, selectedPromotion } = usePromotions();

  const classes = useModalsStyles();

  const [filteredWallets, setFilteredWallets] = useState([]);

  const [inputCellphone, setInputCellphone] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');
  const [fullMask, setFullMask] = useState('+55(99)99999-9999');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputCellphone('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMask(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setCellphone(currentDDD + inputCellphone.replace(/\D/g, ''));
  }, [inputCellphone]);

  const attendanceServices = new AttendanceServices({});

  const handleSearch = (userInfo, walletInfo) => {
    setUserData(userInfo.userv3);
    setWalletData(walletInfo);

    walletInfo.activeCoupons &&
      setFilteredWallets(
        walletInfo.activeCoupons.filter(
          ({ establishmentId, ...item }) =>
            establishmentId === location.state?.establishmentId &&
            (item.state !== 'finished' || item.status !== 'consumido')
        )
      );
  };

  const handleVoucherConsume = () => {
    setInformationLoading(true);

    const voucherConsumed = true;

    const clientList = new File([userData?.triiboId], 'fakeFile.txt');

    voucherSendComponent(selectedPromotion.templateId, batchId, clientList, voucherConsumed, () => {
      setInformationLoading(false);

      setSnackBarMessage(`Promoção consumida com sucesso.`);
    });

    // attendanceServices
    //   .validateVoucher(
    //     getUserInfo().triiboId,
    //     selectedPromotion.templateId,
    //     location.state?.lat,
    //     location.state?.long,
    //     "receipt",
    //     userData?.triiboId
    //   )
    //   .then(({ data }) => {
    //     setInformationLoading(false);

    //     if (data.error) {
    //       switch (data.error) {
    //         case "UNIQUE":
    //           return setSnackBarMessage(
    //             "Validação bloqueada por quantidade simultânea!"
    //           );

    //         default:
    //           return setSnackBarMessage("Erro ao validar cupom!");
    //       }
    //     } else {
    //       setSnackBarMessage("Cupom consumido com sucesso!");
    //     }
    //   })
    //   .catch((err) => {
    //     setInformationLoading(false);
    //     setSnackBarMessage("Erro ao consumir cupom!");
    //   });

    // handleCloseModal();
  };

  const handleGiveVoucher = async () => {
    setInformationLoading(true);

    let templateId = '';

    if (optionSelected === 'finalVoucher') {
      templateId = selectedPromotion.finalVoucher.templateId;
    } else {
      templateId = selectedPromotion.templateId;
    }

    const voucherConsumed = false;
    const data = [userData.triiboId];
    const fakeFile = new File(data, 'fakeFile.txt');

    try {
      const result = await attendanceServices.getVouchers(templateId);
      if (!result.generator && result.qtdDisponivel <= 0) {
        toast.warning('Essa promoção não possui cupons restantes.', {
          autoClose: 2000,
        });
        return;
      }

      await voucherSendComponent(templateId, batchId, fakeFile, voucherConsumed, (response) => {
        if (response?.error) {
          const errorMessage = response.message?.response?.data?.error || 'Erro desconhecido';
          toast.error(`Erro ao enviar promoção: ${errorMessage}`, {
            autoClose: 2000,
          });
          setOpenModal(false);
        } else {
          toast.success('Promoção enviada com sucesso!', { autoClose: 2000 });
          setOpenModal(false);
        }
      });
    } catch (error) {
      toast.error('Erro ao enviar promoção: ', error, { autoClose: 2000 });
    }
  };

  const renderPromotionModalCards = () => {
    if (currentPromotions.length === 0)
      return (
        <Box display="flex" flexDirection="column" alignItems="center" style={{ margin: '0 auto' }}>
          <SentimentDissatisfied color="disabled" classes={{ colorDisabled: classes.sadIcon }} />
          <br />
          <Typography variant="body2" color="textSecondary" component="span">
            Seu estabelecimento não possui promoções!
          </Typography>
        </Box>
      );
    return currentPromotions.map((promotion) => {
      const isActive =
        promotion.isPublished === true &&
        promotion.enableDate <= Date.now() &&
        promotion.disableDate >= Date.now();

      return (
        promotion.type === 'promotion' &&
        isActive && <PromotionModalCard {...{ classes, promotion }} />
      );
    });
  };

  const renderConsumeVoucherCards = () => {
    if (filteredWallets.length === 0)
      return (
        <Box display="flex" flexDirection="column" alignItems="center" style={{ margin: '0 auto' }}>
          <SentimentDissatisfied color="disabled" classes={{ colorDisabled: classes.sadIcon }} />
          <br />
          <Typography variant="body2" color="textSecondary" component="span">
            Este usuário não possui cupons do seu estabelecimento.
          </Typography>
        </Box>
      );

    return currentPromotions.map((promotion) => {
      const isActive =
        promotion.isPublished === true &&
        promotion.enableDate <= Date.now() &&
        promotion.disableDate >= Date.now();

      return (
        promotion.type !== 'businessPartner' &&
        isActive && <ConsumeVoucherCard key={promotion.key} {...{ classes, promotion }} />
      );
    });
  };

  const ConditionalCards = () => (
    <Box
      display="flex"
      justifyContent={currentPromotions.length === 0 ? 'center' : 'flex-start'}
      className={classes.content}
    >
      {optionSelected === 'promotions' || optionSelected === 'finalVoucher'
        ? renderPromotionModalCards()
        : renderConsumeVoucherCards()}
    </Box>
  );

  const ActionButton = () => {
    const informationValues = Object.values(optionSelected && selectedPromotion).length;

    let actionFunction = '';
    // optionSelected === "promotions" || optionSelected === "finalVoucher"
    //   ? handleGiveVoucher
    //   : handleVoucherConsume;

    // VERIFICA O TIPO DE AÇÃO DO CUPOM
    if (optionSelected === 'finalVoucher' && nameButtonLabel === 'Dar Cupom Final') {
      actionFunction = handleGiveVoucher;
    } else if (optionSelected === 'finalVoucher' && nameButtonLabel === 'Consumir Cupom') {
      actionFunction = handleVoucherConsume;
    } else if (optionSelected === 'promotions' && nameButtonLabel === 'Dar cupom') {
      actionFunction = handleGiveVoucher;
    } else if (optionSelected === 'consumeVoucher' && nameButtonLabel === 'Consumir cupom') {
      actionFunction = handleVoucherConsume;
    } else {
      actionFunction = '';
    }

    return (
      <>
        {informationValues > 0 && (
          <Button
            variant="contained"
            color="primary"
            classes={{ contained: classes.button }}
            onClick={actionFunction}
            disabled={informationLoading}
          >
            {/* {(optionSelected === "promotions" && !informationLoading) ||
              (optionSelected === "finalVoucher" && !informationLoading)
                ? optionSelected === "finalVoucher"
                  ? "Dar Cupom Final"
                  : "Consumir Cupom"
                : optionSelected === "promotions"
                ? "Dar cupom"
                : "Consumir cupom"} */}
            {informationLoading ? (
              <CircularProgress size={25} color="default" classes={{ root: classes.loadingIcon }} />
            ) : (
              nameButtonLabel
            )}
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    const { templateId } = selectedPromotion;

    templateId &&
      attendanceServices.getVouchers(templateId).then((result) => {
        setBatchId(result?.batchId);
      });

    if (userInfo && walletInfo) {
      setUserData(userInfo);
      setWalletData(walletInfo);
    }
  }, [selectedPromotion]);

  useEffect(() => {
    if (newStreet === '') {
      return;
    }
    function searchLatLong() {
      const address = encodeURIComponent(
        `${newStreet} ${newNumber} ${newNeighborhood} ${newCity} ${newState}`
      );

      geocoding(address)
        .then((result) => {
          if (result.status === 'OK' && result.results.length > 0) {
            setNewLatitude(result.results[0].geometry.location.lat);
            setNewLongitude(result.results[0].geometry.location.lng);
          } else {
            setNewLatitude(0);
            setNewLongitude(0);
          }
        })
        .catch((error) => {
          setNewLatitude(0);
          setNewLongitude(0);
        });
    }

    searchLatLong();
  }, [newStreet, newCity, newNeighborhood, newNumber, newState]);

  const handleNewZipCode = (value) => {
    value = value.replace(/[a-z]|[A-Z]|-/g, '');

    setNewZipCode(value);

    if (value.length >= 8) {
      axios.get(`https://viacep.com.br/ws/${value}/json/`).then((result) => {
        const response = result.data;

        setNewState(response.uf);
        setNewCity(response.localidade);
        setNewNeighborhood(response.bairro);
        setNewStreet(response.logradouro);
      });
    }
  };

  useEffect(() => {
    setNewBirthDate('');
    setNewCity('');
    setNewEmail('');
    setNewExtra('');
    setNewLatitude(0);
    setNewLongitude(0);
    setNewName('');
    setNewNeighborhood('');
    setNewNumber('');
    setNewState('');
    setNewStreet('');
    setNewZipCode('');
  }, []);

  const handleRegister = (event) => {
    event.preventDefault();
    setLoadingRegister(true);

    const getIncomingBirthDate = newBirthDate === '' ? null : newBirthDate.split('/');

    let address = {
      zipCode: newZipCode,
      country: newCountry,
      state: newState,
      city: newCity,
      neighborhood: newNeighborhood,
      streetAve: newStreet,
      number: newNumber,
      extra: newExtra,
      latitude: newLatitude,
      longitude: newLongitude,
    };

    if (newZipCode === '' || newZipCode.length !== 8) {
      address = null;
    }

    for (let item in address) {
      if (address[item] === '') {
        delete address[item];
      }
    }

    const userInformation = {
      name: newName,
      email: newEmail,
      birthDate:
        getIncomingBirthDate !== null
          ? new Date(
              `${getIncomingBirthDate[2]}/${getIncomingBirthDate[1]}/${getIncomingBirthDate[0]}`
            ).getTime()
          : null,
      addressList: address === null ? [] : [address],
    };

    if (newEmail.trim() === '') {
      delete userInformation.email;
    }

    if (!userInformation.birthDate) {
      delete userInformation.birthDate;
    }

    const triiboId = `${cellphone}@sms,triibo,com,br`;

    const formatMask = '+' + fullMask.replace(/\D/g, '');

    if (cellphone.length !== formatMask.length) {
      setSnackBarMessage('Número do usuário inválido.');
      setLoadingRegister(false);
      return;
    }

    RegisterUsers(cellphone, userInformation, establishmentId, establishmentName)
      .then((result) => {
        setUserData({ triiboId });
        setLoadingRegister(false);
        setIsUserRegister(false);
        setSnackBarMessage('Usuário cadastrado com sucesso!');
      })
      .catch((error) => {
        console.error(error);
        setLoadingRegister(false);
        setSnackBarMessage('Ops, usuário não foi cadastrado.');
      });
  };

  return (
    <Box className={classes.paper}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarMessage.length > 0}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
        autoHideDuration={4000}
      />
      <Box>
        {!userInfo && !walletInfo && !isUserRegister && (
          <Header establishmentName={location.state.nome} isModalHeader action={handleSearch} />
        )}
        {isUserRegister && (
          <div>
            <Typography variant="h4" color="primary" className={classInputRegister.title}>
              Este usuário não possui cadastro. Deseja cadastrá-lo?
            </Typography>
            <form onSubmit={handleRegister} className={classInputRegister.form}>
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newName}
                    autoFocus
                    required
                    placeholder="Nome completo*"
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newEmail}
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <S.cellPhoneBox height={'55px'}>
                    <select
                      style={{
                        fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      }}
                      value={currentCountry}
                      onChange={handleCountryChange}
                    >
                      {phoneMaskArray.map((country, index) => (
                        <option
                          style={{
                            fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                          }}
                          key={country.id}
                          value={[country.ddd, country.mask, country.fullMask]}
                        >
                          {country.emoji} {country.initials} {country.ddd}
                        </option>
                      ))}
                    </select>

                    <InputMask
                      mask={inputMask}
                      value={inputCellphone}
                      placeholder={randomizeNumbersString(inputMask)}
                      onChange={(e) => setInputCellphone(e.target.value)}
                      className="inputCellphone"
                    />
                  </S.cellPhoneBox>
                  {/* <InputMask
                      mask='(99) 9 9999-9999'
                      value={currentCellphone}
                      onChange={(event) => setCellphone(event.target.value)}
                      maskChar=' '>
                      {() => (
                        <TextField
                          id='outlined-basic'
                          variant='outlined'
                          placeholder='Digite seu celular*'
                          fullWidth
                          required
                          size='medium'
                        />
                      )} */}
                </Grid>
                <Grid item xs={6}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newBirthDate}
                    type="date"
                    placeholder="Data de nascimento"
                    onChange={(e) => setNewBirthDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newZipCode}
                    placeholder="CEP"
                    onChange={(e) => handleNewZipCode(maskCEP(e.target.value))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OutlinedInput fullWidth={true} value={newCountry} disabled placeholder="País" />
                </Grid>
                <Grid item xs={6}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newState}
                    disabled
                    placeholder="Estado"
                    onChange={(e) => setNewState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newCity}
                    disabled
                    placeholder="Cidade"
                    onChange={(e) => setNewCity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newNeighborhood}
                    disabled
                    placeholder="Bairro"
                    onChange={(e) => setNewNeighborhood(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newStreet}
                    disabled
                    placeholder="Rua"
                    onChange={(e) => setNewStreet(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newNumber}
                    placeholder="Número"
                    onChange={(e) => setNewNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OutlinedInput
                    fullWidth={true}
                    value={newExtra}
                    placeholder="Complemento"
                    onChange={(e) => setNewExtra(e.target.value)}
                  />
                </Grid>
              </Grid>

              <div className={classInputRegister.contentButton}>
                <Button
                  onClick={() => {
                    setIsUserRegister(false);
                    setOpenModal(false);
                  }}
                  type="button"
                  className={classInputRegister.button}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Cancelar
                </Button>
                <Button type="submit" variant="contained" color="primary" disableElevation>
                  {loadingRegister ? (
                    <CircularProgress
                      size={25}
                      color="default"
                      classes={{ root: classInputRegister.loadingIcon }}
                    />
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </div>
            </form>
          </div>
        )}
        <br />
        <br />

        {Object.values(userData).length > 0 && (
          <>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" color="primary" component="span">
                {userData.name || 'Nome do usuário'}
              </Typography>

              <Typography variant="body1" color="primary" component="span">
                Pontos: {walletData.totalPoints || 0}
              </Typography>
            </Box>

            <hr />
            <br />

            <Box display="flex" flexDirection="column">
              <Typography variant="body2" color="primary" component="span">
                {optionSelected === 'promotions' || optionSelected === 'finalVoucher'
                  ? 'Minhas promoções'
                  : 'Carteira:'}
              </Typography>

              <br />

              <ConditionalCards />

              <br />

              <ActionButton />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
});

// redux connection config
//recebe as props dos Reducers
const mapStateToProps = (state) => {
  return {
    loading: state.voucherSendComponent.loading,
    success: state.voucherSendComponent.success,
    error: state.voucherSendComponent.error,
  };
};
//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  voucherSendComponent: (templateId, batchId, emailFile, consumed, callback) =>
    voucherSendAction(dispatch, templateId, batchId, emailFile, consumed, callback),
});
export const PromotionModalForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionModalFormComponent);

// modal animation config
export const Fade = React.memo(
  React.forwardRef((props, ref) => {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });

    return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
    );
  })
);

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};
