import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { blobToBase64, separateBase64String, b64toBlob } from 'js/library/utils/helpers';
import { downloadImageAsBlob } from 'js/library/services/StorageManager';
import { GetKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';
import { createFeedPost } from 'js/library/utils/API/Posts/apiCreateFeedPost';
import { updatePost } from 'js/library/utils/API/Posts/apiUpdatePost';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import sendIcon from '../../../../../styles/assets/communications/send.svg';
import eyeIcon from '../../../../../styles/assets/communications/eye.svg';

import TitleAndMessage from './TitleAndMessage';
import ImageCrop from './ImageCrop';
import ActionsButtons from './ActionsButtons';
import PostPreview from './PostPreview';
import ModalShedulePublication from './ModalShedulePublication';
import ModalPreview from './ModalPreview';
import ModalConfirmSubmit from './ModalConfirmSubmit';
import SelectKeywords from './SelectKeywords';
import Platform from './Platform';
import ButtonText from './ButtonText';
import Sender from './Sender';
import Orgs from './Orgs';
import { toast } from 'react-toastify';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { store } from 'js/core/configureStore';
import { getOrgById } from 'js/library/utils/API/Org/apiGetOrgById';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';
import { PublishDates } from './PublishDates';
import { getUserInfo } from 'js/library/utils/helpers';

export default function SendPosts({ history, ...props }) {
  const [currentPageMobile, setCurrentPageMobile] = useState(0);

  const storedType = localStorage.getItem('type');

  const params = useParams();

  const userInfo = getUserInfo();

  const clubeInfo = props?.location?.state?.clubeInfo;

  const sender = { ...props.location.state.sender };
  sender.type = props.location.state.sender.type === 'club' ? 'org' : 'establishment';
  const isClubPage = sender.type === 'org';
  const [typeLink, setTypeLink] = useState('new-guide');

  const isGestorPage = isClubPage
    ? true
    : window.location.href.split('/').includes('admin')
    ? true
    : false;

  const [formPost, setFormPost] = useState({
    sender: {
      ...sender,
    },
    id: '',
    appFilter: {
      itemId: '',
      query: '',
      url: '',
    },
    title: '',
    message: '',
    image: '',
    orderType: 'alphabetic',
    action: {
      label: '',
      type: 'establishment_list',
    },
    keywords: [],
    platform: ['web', 'android', 'iOS'],
    orgs: [],
    receiverPlaces: [],
    receiverOrgs: [],
    publishDate: '',
    expirationDate: '',
  });

  // DATE FUNCTIONS

  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let date = now.getDate();
  let hours = now.getHours();
  let minutes = now.getMinutes();

  let formattedNow = `${year}-${month < 10 ? `0${month}` : month}-${
    date < 10 ? `0${date}` : date
  }T${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

  let future = new Date();
  future.setFullYear(future.getFullYear() + 30);
  let futureYear = future.getFullYear();
  let futureMonth = future.getMonth() + 1;
  let futureDate = future.getDate();

  let formattedFuture = `${futureYear}-${futureMonth < 10 ? `0${futureMonth}` : futureMonth}-${
    futureDate < 10 ? `0${futureDate}` : futureDate
  }T00:00`;

  //ACTIVATION AND DEACTIVATION DATE STATES
  const [enableDate, setEnableDate] = useState(formattedNow);
  const [disableDate, setDisableDate] = useState(formattedFuture);

  // STATES OF COMPONENT IMAGECROP
  const [image, setImage] = useState('');
  const [previousImage, setPreviousImage] = useState('');

  const [togglePreview, setTogglePreview] = useState(false);

  const [selectedOptionActionButton, setSelectedOptionActionButton] = useState('');

  const [selectedOptionButtonsText, setSelectedOptionButtonsText] = useState('');

  // STATES DOS MODAIS E SEUS VALORES
  const [isModalShedulePublicationOpen, setIsModalShedulePublicationOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [isModalConfirmSubmitOpen, setIsModalConfirmSubmitOpen] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(0);

  const [typeSubmit, setTypeSubmit] = useState('');
  const [fieldsAreEmpty, setFieldsAreEmpty] = useState('');
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [orgs, setOrgs] = useState([]);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  const [selectedEstablishments, setSelectedEstablishments] = useState([]);

  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  const navigate = useHistory();
  const senderType = props.location.state.sender.name ? formPost.sender.type : 'admin';

  useEffect(() => {
    if (props.location.state.id) {
      let newObj = props.location.state;

      delete newObj.updateDate;
      delete newObj.creationDate;

      delete newObj.newObj;

      setPreviousImage(newObj.image || '');

      setFormPost({ ...newObj });

      setFieldsAreEmpty(false);

      if (newObj.appFilter) {
        if (newObj.appFilter.url) {
          setSelectedOptionActionButton('Enviar para link externo');
        }
        if (newObj.appFilter.query) {
          setSelectedOptionActionButton('Listagem de promoções ou estabelecimentos');
        }
        if (newObj.appFilter.itemId) {
          setSelectedOptionActionButton('Acessar perfil do Negócio');
        }

        if (newObj.action.label) {
          setSelectedOptionButtonsText(newObj.action.label);
        }
      }
    }
    GetKeywords().then((res) => {
      setKeywords(res);
    });
  }, []);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 600);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  }, []);

  const redirectToPostList = () => {
    if (storedType === 'home') {
      navigate.push('/admin/configuracoes/listaPostagem');
    } else {
      navigate.push('/admin/seu-clube');
    }
  };

  const handleCloseModal = (modal) => {
    if (modal === 'shedulePublication') {
      setIsModalShedulePublicationOpen(false);
    }

    if (modal === 'preview') {
      setIsModalPreviewOpen(false);
    }

    if (modal === 'confirmSubmit') {
      setIsModalConfirmSubmitOpen(false);
    }
  };

  const handleTogglePreview = () => {
    setTogglePreview(!togglePreview);
  };

  const handleCloseModalConfirmSubmit = () => {
    setIsModalConfirmSubmitOpen(false);
  };

  const uploadingFile = async (file) => {
    let imagePath = '';

    const newFile = separateBase64String(await blobToBase64(file));
    await uploadFiles('postFiles', {
      mimeType: newFile.mimeType,
      fileName: 'postFileImage',
      buffer: newFile.buffer,
    })
      .then((res) => {
        const response = res.split('/');
        imagePath = 'postFiles/' + response[response.length - 1].split('?')[0];
      })
      .catch(() => {
        imagePath = '';
      });

    return imagePath;
  };

  const submitCommunication = async (type) => {
    setLoading(true);

    if (fieldsAreEmpty) {
      toast.warn(fieldsAreEmpty);
      setLoading(false);
      return;
    }

    let imageFormatted;

    if (image) {
      if (image.indexOf('https') === 0) {
        const imageBlob = await downloadImageAsBlob(image);
        imageFormatted = imageBlob;
      } else {
        imageFormatted = b64toBlob(image);
      }
    }

    const objTemplatePost = {
      ...formPost,

      publishDate: Date.parse(enableDate),
      expirationDate: Date.parse(disableDate),
      image: image ? await uploadingFile(imageFormatted) : formPost.image,
    };

    delete objTemplatePost.id;

    objTemplatePost.sender.logo = isGestorPage
      ? 'estabelecimento/' + store.getState().currentEstModel.currentEst.fotoThumb
      : formPost.sender.logo;

    if (objTemplatePost.sender.type === 'org') {
      const response = await getOrgById(objTemplatePost.sender.id);

      if (response) {
        objTemplatePost.sender.logo = response.orgInfo.thumbnail;
      }
    }

    delete objTemplatePost.sendDate;
    delete objTemplatePost.clubeInfo;

    //tratando url do banner para inserir frame
    if (typeLink === 'inside-frame') {
      objTemplatePost.appFilter.url =
        'https://' +
        clubeInfo.clubeId +
        clubeInfo.url +
        '/pagina-interna/?url=' +
        encodeURIComponent(objTemplatePost.appFilter.url);
    } else if (typeLink === 'full-frame') {
      objTemplatePost.appFilter.url =
        'https://' +
        clubeInfo.clubeId +
        clubeInfo.url +
        '/pagina-completa/?url=' +
        encodeURIComponent(objTemplatePost.appFilter.url);
    }

    if (formPost.id && !fieldsAreEmpty) {
      updatePost(formPost.id, objTemplatePost)
        .then((res) => {
          toast.success('Postagem editada com sucesso!');
          setLoading(false);
          setTimeout(redirectToPostList, 2000);
          handleCloseModalConfirmSubmit();
          setImage('');
          setSelectedOptionActionButton('');
        })
        .catch(() => {
          toast.error('Houve um erro ao enviar a postagem.');
          setLoading(false);
          handleCloseModalConfirmSubmit();
        });
    } else {
      createFeedPost(objTemplatePost)
        .then(async (res) => {
          toast.success('Postagem criada com sucesso!');
          setLoading(false);
          setTimeout(redirectToPostList, 2000);
          handleCloseModalConfirmSubmit();
          setImage('');
          setSelectedOptionActionButton('');
          setSelectedEstablishments([]);
          setSelectedOrganizations([]);
          setFormPost({
            sender: {
              id: isGestorPage ? store.getState().currentEstModel.currentEst.id : '',
              name: isGestorPage ? store.getState().currentEstModel.currentEst.nome : '',
              type: 'establishment',
              logo: isGestorPage
                ? 'estabelecimento/' + store.getState().currentEstModel.currentEst.fotoThumb
                : '',
            },
            appFilter: {
              itemId: '',
              query: '',
              url: '',
            },
            title: '',
            message: '',
            image: '',

            orderType: 'alphabetic',
            action: {
              label: '',
              type: 'establishment_list',
            },
            keywords: [],
            platform: ['web', 'android', 'iOS'],
            orgs: [],
            receiverPlaces: [],
            receiverOrgs: [],
            publishDate: '',
            expirationDate: '',
          });
        })
        .catch(() => {
          toast.error('Houve um erro ao enviar a postagem.');
          setLoading(false);
          handleCloseModalConfirmSubmit();
        });
    }
  };

  const arrElements = [
    <S.cols key={1}>
      {!isGestorPage && !props.location.state.sender.name && (
        <Platform form={formPost} setForm={setFormPost} />
      )}
      {formPost.sender.type === 'org' && (
        <Orgs form={formPost} setForm={setFormPost} options={orgs} setOptions={setOrgs} />
      )}
      {userInfo.admin && (
        <Sender
          form={formPost}
          setForm={setFormPost}
          setOrgs={setOrgs}
          selectedEstablishments={selectedEstablishments}
          setSelectedEstablishments={setSelectedEstablishments}
          selectedOrganizations={selectedOrganizations}
          setSelectedOrganizations={setSelectedOrganizations}
        />
      )}
      <TitleAndMessage formCommunication={formPost} setFormCommunication={setFormPost} />
    </S.cols>,
    <S.cols key={2}>
      <ImageCrop
        image={image}
        setImage={setImage}
        previousImage={previousImage}
        setPreviousImage={setPreviousImage}
      />

      <ActionsButtons
        typeLink={typeLink}
        setTypeLink={setTypeLink}
        formCommunication={formPost}
        setFormCommunication={setFormPost}
        selectedOptionActionButton={selectedOptionActionButton}
        setSelectedOptionActionButton={setSelectedOptionActionButton}
        senderType={props.location.state.sender.name ? formPost.sender.type : 'admin'}
      />
      <PublishDates
        setEnableDate={setEnableDate}
        setDisableDate={setDisableDate}
        enableDate={enableDate}
        disableDate={disableDate}
      />
      <ButtonText
        form={formPost}
        setForm={setFormPost}
        selectedOptionButtonsText={selectedOptionButtonsText}
        setSelectedOptionButtonsText={setSelectedOptionButtonsText}
      />
      {isGestorPage ? (
        <></>
      ) : !props.location.state.sender.name ? (
        <SelectKeywords
          form={formPost}
          setForm={setFormPost}
          keywordsArr={keywords}
          setKeywordsArr={setKeywords}
        />
      ) : (
        <></>
      )}
    </S.cols>,
  ];

  useEffect(() => {
    setFieldsAreEmpty(false);

    if (formPost.title === '' || formPost.message === '') {
      setFieldsAreEmpty("Preencha os campos 'Título' e 'Mensagem'");
      setLoading(false);
      return;
    }

    if (
      selectedOptionActionButton === 'Abrir uma promoção/produto' &&
      formPost.appFilter.itemId === ''
    ) {
      setFieldsAreEmpty("O campo 'itemId' nao existe");
      setLoading(false);
      return;
    }
    if (
      selectedOptionActionButton === 'Listagem de promoções ou estabelecimentos ' &&
      formPost.appFilter.query === ''
    ) {
      setFieldsAreEmpty("O campo 'query' nao existe");
      setLoading(false);
      return;
    }
    if (
      selectedOptionActionButton === 'Enviar para link externo' &&
      formPost.appFilter.url === ''
    ) {
      setFieldsAreEmpty("Preencha o campo 'url' nao existe");
      setLoading(false);
      return;
    }
    if (!params.id) {
      setFieldsAreEmpty('O campo sender.id não existe');
      setLoading(false);
      return;
    }
  }, [formPost, image, selectedOptionActionButton]);

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <S.Container>
        <S.Header>
          <D.TriiboH1 style={{ marginTop: '-3rem', marginBottom: '2rem' }}>
            Criação de postagem
          </D.TriiboH1>
          <D.TriiboH4>
            A notificação/post é uma forma rápida e prática de se comunicar com seus clientes.
            Utilize para enviar textos e imagens no aplicativo Triibo.
          </D.TriiboH4>
        </S.Header>
        <S.Content>
          {isDesktop ? (
            <div className="desktop">
              <S.cols>
                {!isGestorPage && !props.location.state.sender.name && (
                  <Platform form={formPost} setForm={setFormPost} />
                )}
                {formPost.sender.type === 'org' && (
                  <Orgs form={formPost} setForm={setFormPost} options={orgs} setOptions={setOrgs} />
                )}
                {userInfo.admin && (
                  <Sender
                    form={formPost}
                    setForm={setFormPost}
                    setOrgs={setOrgs}
                    selectedEstablishments={selectedEstablishments}
                    setSelectedEstablishments={setSelectedEstablishments}
                    selectedOrganizations={selectedOrganizations}
                    setSelectedOrganizations={setSelectedOrganizations}
                  />
                )}

                <TitleAndMessage formCommunication={formPost} setFormCommunication={setFormPost} />
              </S.cols>
              <S.cols>
                <ImageCrop
                  image={image}
                  setImage={setImage}
                  setPreviousImage={setPreviousImage}
                  previousImage={previousImage}
                />

                <ActionsButtons
                  typeLink={typeLink}
                  setTypeLink={setTypeLink}
                  formCommunication={formPost}
                  setFormCommunication={setFormPost}
                  selectedOptionActionButton={selectedOptionActionButton}
                  setSelectedOptionActionButton={setSelectedOptionActionButton}
                  enableDate={enableDate}
                  setEnableDate={setEnableDate}
                  setDisableDate={setDisableDate}
                  disableDate={disableDate}
                  senderType={senderType}
                />
                <PublishDates
                  setEnableDate={setEnableDate}
                  setDisableDate={setDisableDate}
                  enableDate={enableDate}
                  disableDate={disableDate}
                  setFieldsAreEmpty={setFieldsAreEmpty}
                />
                <ButtonText
                  form={formPost}
                  setForm={setFormPost}
                  selectedOptionButtonsText={selectedOptionButtonsText}
                  setSelectedOptionButtonsText={setSelectedOptionButtonsText}
                />
                {isGestorPage ? (
                  <></>
                ) : !props.location.state.sender.name ? (
                  <SelectKeywords
                    form={formPost}
                    setForm={setFormPost}
                    keywordsArr={keywords}
                    setKeywordsArr={setKeywords}
                  />
                ) : (
                  <></>
                )}
              </S.cols>
              <S.cols className="previewContainer">
                <div className="preview">
                  <S.PreviewContainer>
                    {<PostPreview formCommunication={formPost} image={image} />}
                  </S.PreviewContainer>
                </div>
              </S.cols>
            </div>
          ) : (
            <div className="mobile">{arrElements[currentPageMobile]}</div>
          )}
        </S.Content>

        <S.ButtonsContainer>
          <S.NextPageButton
            onClick={() => setCurrentPageMobile(1)}
            currentPageMobile={currentPageMobile}
          >
            Avançar
          </S.NextPageButton>

          <S.ButtonPreview
            onClick={() => setIsModalPreviewOpen(true)}
            currentPageMobile={currentPageMobile}
          >
            <img src={eyeIcon} alt="" />
            Visualizar prévia
          </S.ButtonPreview>

          {/* <S.ButtonSchedulePublication
          onClick={() => setIsModalShedulePublicationOpen(true)}
          currentPageMobile={currentPageMobile}
        >
          <img src={calendarIcon} alt="" />
          Agendar publicação
        </S.ButtonSchedulePublication> */}

          <S.ButtonPublish
            onClick={() => {
              setTypeSubmit('publishNow');
              setIsModalConfirmSubmitOpen(true);
            }}
            currentPageMobile={currentPageMobile}
          >
            <img src={sendIcon} alt="" />
            {!formPost.id ? 'Publicar agora' : 'Editar agora'}
          </S.ButtonPublish>

          <S.ButtonGoBack
            onClick={() => {
              currentPageMobile === 0 ? history.goBack() : setCurrentPageMobile(0);
            }}
          >
            Voltar
          </S.ButtonGoBack>
        </S.ButtonsContainer>

        <ModalShedulePublication
          handleCloseModal={handleCloseModal}
          isModalShedulePublicationOpen={isModalShedulePublicationOpen}
          setScheduledDate={setScheduledDate}
          setTypeSubmit={setTypeSubmit}
          setIsModalConfirmSubmitOpen={setIsModalConfirmSubmitOpen}
          scheduledDate={scheduledDate}
        />

        <ModalPreview
          handleCloseModal={handleCloseModal}
          isModalImageCropOpen={isModalPreviewOpen}
          togglePreview={togglePreview}
          formCommunication={formPost}
          image={image}
          handleTogglePreview={handleTogglePreview}
        />

        <ModalConfirmSubmit
          handleCloseModal={handleCloseModal}
          isModalConfirmSubmitOpen={isModalConfirmSubmitOpen}
          typeSubmit={typeSubmit}
          submitCommunication={submitCommunication}
          loading={loading}
        />
      </S.Container>
    </div>
  );
}

