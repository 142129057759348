import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  padding: 1rem;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  h1 {
    text-align: center;
  }

  @media (max-width: 490px) {
    padding: 0;
  }
`;

export const ContainerUserCampaign = styled.div`
  padding: 3rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 70%;
`;

export const GridOption = styled.div`
  width: 70%;
  display: grid;
  justify-items: center;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  gap: 25px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const UserCompaignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem auto;
  max-width: 1000px;
  width: 100%;
  gap: 2rem;
  align-items: center;

  @media (max-width: 899px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InputBox = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 5px;

  @media (max-width: 899px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
  text-align: left;
  display: ${({ filtered }) => (filtered ? 'block' : 'flex')};
  width: 100%;
  position: absolute;
  bottom: -1.5rem;

  @media (max-width: 899px) {
    position: static;
  }
  padding: '10px 30px';
`;

export const AddUserButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  background-color: #6e32960c;
  color: #6e3296;
  border: 1px dashed #6e3296;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  span {
    font-size: 24px;
    margin-right: 8px;
  }

  &:hover {
    background-color: #6e329633;
  }
  @media (max-width: 899px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0.4rem 1rem;
  font-size: 16px;
  height: 100%;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  @media (max-width: 490px) {
    font-size: 14px;
    width: 200px;
  }
`;

export const SearchComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 490px) {
    font-size: 10px;
  }
`;

export const SearchIconButton = styled.button`
  background: none;
  height: 100%;
  border-left: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  color: #6e3296;
  cursor: pointer;
  padding: 0 4px 0 10px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;
